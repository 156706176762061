<template>
  <div class="testimonial left">
    <p class="quote">{{ quoteOne }}</p>
    <h2 class="person">{{ quoteOneName }},<br />{{ quoteOneRole }}</h2>
  </div>
  <div class="testimonial right">
    <p class="quote">{{ quoteTwo }}</p>
    <h2 class="person">{{ quoteTwoName }},<br />{{ quoteTwoRole }}</h2>
  </div>
  <div class="clear-both"></div>
</template>

<script>
export default {
  name: "Slide",
  props: {
    quoteOne: String,
    quoteOneName: String,
    quoteOneRole: String,
    quoteTwo: String,
    quoteTwoName: String,
    quoteTwoRole: String,
  },
};
</script>

<style lang="scss" scoped>
.testimonial {
  max-width: 450px;

  @apply ml-4 mb-16 mt-2 relative;

  &::before {
    content: '"';
    position: absolute;
    font-family: TitleFont;
    font-size: 70px;
    font-style: italic;
    line-height: 70px;
  }

  &.left {
    box-shadow: 9px -9px 0px $secondaryColour;
    &::before {
      color: $primaryColour;
      top: -15px;
      left: -15px;
    }

    .quote {
      border: 2px solid $secondaryColour;
    }

    .person {
      color: $secondaryColour;
    }
  }

  &.right {
    box-shadow: -9px -9px 0px $primaryColour;
    @apply float-right mr-4;

    &::before {
      color: $secondaryColour;
      top: -15px;
      left: -15px;
    }

    .quote {
      border: 2px solid $primaryColour;
    }

    .person {
      color: $primaryColour;
    }
  }

  .quote {
    background: white;
    @apply p-4;
  }

  .person {
    background: rgba(white, 0.85);
    @apply absolute right-2 px-2 -bottom-6;
  }
}
</style>
