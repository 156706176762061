<template>
  <div class="headline-container">
    <div class="md:col-span-3">
      <h1 class="text-xl italic">Can I trust you?</h1>
      <p class="pt-5">
        You can't just take my word for it, so here are some comments I've
        gathered along the way.
      </p>
    </div>
    <Profile />
  </div>
  <div class="gradient-section">
    <swiper
      class="testimonials"
      navigation
      :slides-per-view="1"
      :space-between="50"
      loop="true"
    >
      <swiper-slide>
        <slide
          quoteOne="Thank you so much for your work on the KEIGWIN +
            COMPANY website - I absolutely love what you created - stylish, simple
            and functional. I also heard nothing but positive feedback about working
            with you. You have the magic combination of talent and great to work
            with. I hope we all get to work together in the future."
          quoteOneName="Larry Keigwin"
          quoteOneRole="Keigwin + Company"
          quoteTwo="Julian is an exceptional front end developer. Julian was able to
            develop a complex site that works seamlessly across all browsers and
            screen sizes. The final product exceeded my expectations. He is also
            a pleasure to work with. I highly recommend him to anyone looking to
            build a sophisticated and functional web presence"
          quoteTwoName="Christine Lhowe, Web Designer"
          quoteTwoRole="Nimbus Dance Works"
        />
      </swiper-slide>
      <swiper-slide>
        <slide
          quoteOne="Julian is highly motivated and exceptional to work with. He provided our company with expert knowledge of web development and has gone above and beyond what was initially requested. As the leader of our new website project, I had the pleasure of working with Julian, and was impressed with his quick response and motivated attitude. We were fortunate to have worked with Julian and he would be an asset to any company!"
          quoteOneName="Anitra Keegan"
          quoteOneRole="Artistic Director at Nimbus Dance"
          quoteTwo="We hired Julian to build our new community website for the Inclusion Nudges non-profit initiative www.inclusion-nudges.org and he has been so dedicated in finding solutions for challenges and new features. And the best part is his humble approach and flexibility, as well as guidance in how we can maintain the site ourselves. I highly recommend Julian for this kind of work"
          quoteTwoName="Tinna Nielsen, Anthropologist"
          quoteTwoRole="Inclusion Nudges"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import Profile from "../components/Profile.vue";
import Slide from "../components/Slide.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

SwiperCore.use([Navigation]);

export default {
  name: "TestimonialsPage",
  components: { Profile, Slide, Swiper, SwiperSlide },
};
</script>

<style lang="scss" scoped>
::v-deep(.swiper-button-prev),
::v-deep(.swiper-button-next) {
  width: 56px;
  height: 50px;
  position: absolute;
  content: "";
  z-index: 9;
  cursor: pointer;
}

::v-deep(.swiper-button-prev) {
  top: 40%;
  left: 0;
  background-image: url("../assets/images/left.svg");
  background-size: contain;
  background-repeat: no-repeat;

  @screen sm {
    top: 60%;
  }
}

::v-deep(.swiper-button-next) {
  top: 40%;
  right: 0;
  background-image: url("../assets/images/right.svg");
  background-size: contain;
  background-repeat: no-repeat;

  @screen sm {
    top: 20%;
  }
}
</style>
